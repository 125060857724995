@font-face {
  font-family: "Marguerite1";
  src: local("Marguerite1"),
    url(../../../fonts/Marguerite1.ttf.woff) format("woff");
}

@font-face {
  font-family: "GothamLight";
  src: local(GothamLight), url(../../../fonts/GothamLight.ttf) format("ttf");
}
